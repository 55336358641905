body {
  font-family: sans-serif;
  background-color: #2f2f2f;
}

#app_container {
  width: 800px;
  margin:auto;
  margin-top: 20vh;
  padding: 20px;
  background-color: #eeeeee;
  border-radius: 15px;
}

.title-bar {
  margin-left: 3px;
  margin-right: 3px;
  font-size: 28px;
  padding-bottom: 5px;
  border-bottom: 1px solid #20639b;
  margin-bottom: 10px;
  color: #20639b;
}

.content-box {
  width:100%;
}

#settings-box {
  padding: 0 10px 20px 10px;

  display:none;
}

.settings-title {
  color: #3caca3;
  text-transform: uppercase;
}

.form-input {
  width: 100%;
  padding-left: 10px;
  font-size: 18px;
  height:42px;
  border: 2px solid #20639b;
}

.form-input-small {
  font-size: 12px;
  height:38px;
  border-color: #3caca3;
}

.form-table {
  width: 100%;
}

.col-1 {
  width: 80%;
}

a {
  text-decoration: none;
}

.result-bar {
  margin-top: 5px;
  width: 100%;
  height: 42px;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
}

.result-load {
  background-color: #f6d55c;
  color: #000000;
}

.result-true {
  background-color: #3caea3;
  color: #000000;
}

.result-false {
  background-color: #ed553b;
  color: #000000;
}

.form-title {
  margin: 4px 0 2px 0;
  font-size: 12px;
}

#settings-bar {
  top: 0;
  left: 0;
  right: 0;
  background-color: #3caca3;
  padding: 5px 10px;
}

.btn {
  display: inline-table;
  width: 120px;
  background-color: #20639b;
  border:2px solid #20639b;
  color: #ffffff;
  font-size: 16px;
  padding: 8px;
  text-align: center;
}

.btn:hover {
  background-color: #ffffff;
  color:#20639b;
  border: 2px solid #20639b;
}

.btn-settings {
  background-color: #3caea3;
}

.btn-submit {
  width: 87%;
}

#upl_input {
  display: none;
}

